#map {
    position: absolute;
    top: 106px;
    left: 0;
    z-index : 0;
    height: calc(100% - 106px);
    width: 100%;
}

.leaflet-control-zoom  {
    display: none;
}

.pswp__zoom-wrap {
    will-change: auto !important;
    -webkit-backface-visibility: visible !important;
  }

.center.loader  {
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
}

#top-area {
    position: absolute;
    left: 0;
    top: 56px;
    z-index : 2;
    width: 100%;
    pointer-events: none;
}

#erect-all {
    position: relative;
    left: 2px;
    bottom: -2px;
    z-index : 2;
    pointer-events: all;
    padding: 0;
    padding-left: 2px;
    padding-right: 2px;
    float: left;
}

#raise-all {
    position: relative;
    left: 4px;
    bottom: -2px;
    z-index : 2;
    pointer-events: all;
    padding: 0;
    padding-left: 2px;
    padding-right: 2px;
    background: darkgray;
    color: black;
    float: left;
}

#both-side-marking {
    display: inline-block;
    position: relative;
    right: -7px;
    bottom: -2px;
    z-index : 2;
    color: white;
    background: red;
    font-size: 12px;
    height: 38px;
    line-height: 18px;
    width: 143px;
    border-radius: 4px;
    padding-left: 3px;
    pointer-events: all;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#both-side-marking.selected {
    background: rgb(162, 199, 65) !important;
    color: #fefefe;
    border: 1px solid rgba(7, 84, 140, 1);
}

#both-side-marking input {
    float: right;
}

#raise-all:hover {
    background: gray;
}

#traffic-sign-select-container {
    background-color: #07548C;
    border: 1px solid rgb(0, 55, 94);
    width: 100%;
    text-align: center;
    pointer-events: all;
}

#traffic-sign-select {
    min-height: 50px;
    max-height: calc(10vh + 50px) !important;
    overflow-y: scroll;
    background-color: #07548C;
    margin-top: 1px;
    margin-bottom: 2px;
}

#traffic-sign-select-modal {
    max-height: 30vh !important;
    overflow-y: scroll;
    margin-bottom: 5px;
}

.traffic-sign-select-icon {
    max-width: 40px;
    max-height: 40px;
    margin: 2px;
}

#top {
    position: absolute;
    top: 0;
    left: 45px;
    z-index : 2;
    color: white;
}

#top h4 {
    line-height: 56px !important;
}

#modal-area {
    position: fixed;
    width: 90%;
    max-height: 90%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 15px;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    pointer-events: all;
}

.lock-location {
    position: absolute;
    left: 2px;
    bottom: 0;
    z-index : 1;
    color: white;
    background: red;
    font-size: 11px;
    line-height: 30px;
    height: 30px;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.lock-location.selected {
    background: rgb(162, 199, 65) !important;
    color: #fefefe;
    border: 1px solid rgba(7, 84, 140, 1);
}

.traffic-sign-position {
    background: #07548C;
    height: 20px;
    position: relative;
    width: 2px;
    left: 19px;
    top: -15px;
}

.traffic-sign-position:after {
    background: #07548C;
    content: "";
    height: 2px;
    left: -9px;
    position: absolute;
    top: 10px;
    width: 20px;
}

.traffic-sign-position-both-side {
    background: #07548C;
    height: 20px;
    position: relative;
    width: 2px;
    left: 10px;
    top: -15px;
}

.traffic-sign-position-both-side:after {
    background: #07548C;
    content: "";
    height: 2px;
    left: 0px;
    position: absolute;
    top: 10px;
    width: 20px;
}

.traffic-sign-position-both-side:before {
    background: #07548C;
    content: "";
    height: 20px;
    left: 18px;
    position: absolute;
    top: 0;
    width: 2px;
}

.selected-traffic-sign {
    border: 2px #07548C solid;
    height: calc(100% + 4px);
    width: 44px;
    margin-left: -2px;
    margin-top: -2px;
}

.selected-traffic-sign-bottom {
    border-bottom: 2px #07548C solid;
    border-left: 2px #07548C solid;
    border-right: 2px #07548C solid;
    height: 100%;
    width: 44px;
    margin-left: -2px;
}

.selected-traffic-sign-top {
    border-top: 2px #07548C solid;
    border-left: 2px #07548C solid;
    border-right: 2px #07548C solid;
    height: 100%;
    width: 44px;
    margin-left: -2px;
}

.selected-traffic-sign-middle {
    border-left: 2px #07548C solid;
    border-right: 2px #07548C solid;
    height: 100%;
    width: 44px;
    margin-left: -2px;
}

.not-erected {
    opacity: 0.6;
}

.raised {
    opacity: 0.6;
    filter: grayscale(1);
}

#label-category-select {
    display: inline-block;
    color: white;
    text-align: right;
    margin-right: 2px;
    font-size: 14px;
}

#input-category-select {
    color: white;
    width: 130px;
    margin-bottom: 3px;
}

#input-category-select option {
    color: black;
  }
  
#input-category-select.required {
    color: black;
}

.custom-sign-container {
    position: relative;
    text-align: center;
    color: black;
    font-size: 8px;
    letter-spacing: 0;
    font-weight: bold;
}

.custom-sign-text.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-sign-text.right {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
}

.custom-sign-text.left {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
}

.custom-sign-text.bottom {
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40px;
}

.white {
    color: white;
}

.popup-button {
    width: 100%;
    margin-bottom: 2px;
}

#new-category-button {
    background: rgb(162, 199, 65) !important;
    padding-left: 2px;
    padding-right: 2px;
    line-height: 35px;
    margin: 0;
    margin-left: 4px;
}

#delete-category-button {
    background: red;
    padding-left: 2px;
    padding-right: 2px;
    line-height: 35px;
    margin: 0;
    margin-left: 4px;
}

#modal-area-new-category {
    position: fixed;
    width: 95%;
    max-height: 95%;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 15px;
    background: #07548C;
    transform: translate(-50%, -50%);
    z-index: 3;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    pointer-events: all;
    color: white;
    overflow-y: scroll;
}

#modal-area-new-category input {
    color: white;
}

#modal-area-new-category #accept-button {
    background: rgb(162, 199, 65);
    border: none;
}

#modal-area-new-category #decline-button {
    color: white;
    border-color: white;
}

#current-new-category {
    height: 10vh !important;
    overflow-y: scroll;
    margin-bottom: 5px;
}

.delete-button {
    background: red;
    background-image: url('trash_symbol.png');
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    width: 100%;
    height: 25px;
    margin-bottom: 0;
}

.delete-button:hover {
    background-color: darkred;
}

#modal-area-direction-selection {
    position: fixed;
    width: 350px;
    min-height: 400px;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 15px;
    background: #07548C;
    transform: translate(-50%, -50%);
    z-index: 3;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    pointer-events: all;
    color: white;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

#modal-area-direction-selection button {
    margin-top: 80px;
    background-color: rgb(162, 199, 65);
}

#modal-area-direction-selection #background-image {
    position: absolute;
    top: 95px;
    left: 50px;
}

#direction-select {
    position: relative;
    top: 12%;
    margin: auto;
    margin-top: 50px;
}

.direction {
    display: inline-block;
    font-size: 40px;
    cursor: pointer;
    border-left: 2px solid white;
    border-right: 2px solid white;
    width: 125px;
    height: 200px;
    padding-top: 5px;
    margin: 2px;
}

.flip {
    -ms-transform: rotate(180deg);; /* IE 9 */
    -webkit-transform: rotate(180deg);; /* Safari */
    transform: rotate(180deg);;
}

#rotate-object {
    background: red;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: grab;
    margin: auto;
}

.dragging {
    cursor: grabbing !important;
}

a {
    color: rgb(162, 199, 65);
}

a:hover {
    color: rgb(110, 136, 43);
}

input[type="file"] {
    display: none;
}

.file-upload {
    background: #07548C;
    height: 30px;
    border-radius: 4px;
    color: white;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.file-upload:hover {
    background: rgb(0, 55, 94);
}

.close {
    height: 50px;
    width: 50px;
    border: 1px white solid;
    border-radius: 5px;
    position: fixed;
    right: 8px;
    top: 8px;
    cursor: pointer;
    background-color: rgba(7, 84, 140, 0.9);
    z-index: 3;
}

.close:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "\274c"; /* use the hex value here... */
    font-size: 25px; 
    color: #FFF;
    line-height: 50px;
    text-align: center;
}

.close:hover {
    background: white;
}

#toggle-combine-traffic-sign-button {
    padding-left: 2px;
    padding-right: 2px;
    background: rgb(162, 199, 65, 0.2);
    margin-left: 2px;
}

#toggle-combine-traffic-sign-button.on {
    background: rgb(162, 199, 65);
}

.traffic-sign-collection {
    display: inline-block;
    width: 45px;
}

.traffic-sign-collection-item {
    position: relative;
}

.traffic-sign-collection-item.another {
    margin-top: -25px;
}

#clear-button {
    position: absolute;
    right: 2px;
    bottom: 2px;
    z-index : 1;
    padding: 0;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0;
}