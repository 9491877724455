body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  text-align: center;
  font-size: 3.4rem;
}

th {
  text-align: center;
}

td {
  text-align: center;
}

table tr:nth-child(2n) {
  background-color: rgba(7,84,140,0.05);
}

.container {
  margin-top: 5em;
}

button:hover {
  background-color: rgb(0, 55, 94);
  border-color: rgb(0, 55, 94);
}

.button-primary {
  margin-right: 15px;
}

.page {
  margin-top: 54px;
}

.red {
    background-color: red;
}

.yellow {
    background-color: yellow;
    color: black;
}

.green {
    background-color: green;
}

.array-item-list {
  flex-direction: column;
}

.array-item {
  margin: 10px;
  max-width: 175px;
}

.btn-info {
  width: 175px;
  margin-left: 10px;
}

.btn-add > i {
  display: none;
}

.btn-add::after {
  content: "+";
  font-weight: bold;
  font-size: 25px;
}

.array-item-remove > i {
  display: none;
}

.array-item-remove::after {
  content: "-";
  font-weight: bold;
  font-size: 25px;
}

.col_xs-9 {
  float: left;
}

.col_xs-3 {
  float: right;
}

.page-selection {
  text-align: center;
}

.link.selected {
  font-weight: bold;
  font-size: 22px;
  cursor: default;
}

.link {
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.center {
  text-align: center;
}

@media (min-width: 1280px) {
  h1 {
    font-size: 4.6rem;
  }
}